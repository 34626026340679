import { SignProcessConstants, PathConstants } from "../../../../common/constants/Constants";
import { container } from "../../../../core/startup/inversify/inversify.config";
import { TYPES } from "../../../../core/startup/inversify/types";
import { IDialogBox } from "../../../../core/utilities/ui/DialogBox";
import { Role } from "../../../../model/Common/Enums";
import { SignerSignFlowProps } from "../SignerSignFlow";

export enum SignProcessSteps {
    LandingPage = 1,
    Esign = 2,
    Empty = 3,
    SignerProcessComplete = 1000
}


export interface ISignProcessInfo {
    clientId: string,
    lastVisitedStep: SignProcessSteps,
    lastUpdateOn: Date,
    visitedSteps: SignProcessSteps[]
}

export const initialSignProcessInfoModel: ISignProcessInfo = {
    clientId: "",
    lastVisitedStep: SignProcessSteps.LandingPage,
    lastUpdateOn: new Date(),
    visitedSteps: []
}


export interface IClientProcessModel {
    role: Role;
    currentstep: ISignProcessInfo;
    permission: string,
    isSigned: boolean
}

export const initialClientProcessModel: IClientProcessModel = {
    role: Role.None,
    currentstep: initialSignProcessInfoModel,
    permission: "",
    isSigned: false
}

export interface IPublicData {
    primaryDetails: IPrimaryDetails;
    states: ICountryState[];
    isLoading: boolean;
}

export interface ICompanyModel {
    companyId: number,
    companyName: string,
    isTestCompany: boolean
}

export interface IPrimaryDetails {
    companyData: ICompanyModel;
    isOtpRequired: boolean;
    isMobileView: boolean;
    loggedInClient: string;
    clientName: string;
    authenticationInfo: IAuthenticationInfo;
    signerAllowedToDelegate:boolean;
    contactPersonInfo: IUserModel;
    companySubscription: string;
}

export interface ICountryState {
    id: number;
    name: string;
    image: string;
}

export interface IUserModel {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    extension: string;
    countryCode: string;
    email: string;
}

export enum AuthenticationType {
    None = 0,
    First4SSN = 1,
    Last4SSN = 2,
    AccessCodeEmail = 3,
    AccessCodeSMS = 4,
    QuestionAnswer = 5
}

export interface IAuthenticationInfo {
    email: string;
    authenticationType: AuthenticationType;
    maskedMobileNumber: string;
    question: string;
    isLocked: boolean;
    lockedOn: Date;
}

export const unloadedprimaryInfoState: IPublicData = {
    primaryDetails: {
        companyData: {
            companyId: 0,
            companyName: "",
            isTestCompany: true
        },
        isOtpRequired: false,
        isMobileView: false,
        loggedInClient: "",
        clientName: "",
        authenticationInfo: {
            email: "",
            authenticationType: AuthenticationType.None,
            maskedMobileNumber: "",
            question: "",
            isLocked: false,
            lockedOn: new Date()
        },
        signerAllowedToDelegate : true,
        contactPersonInfo: {
            countryCode: "",
            email: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            extension: ""
        },
        companySubscription: ""
    },
    states: [],
    isLoading: true
};

export const unloadedprimaryInfoState1: IPublicData = {
    primaryDetails: {
    }
} as IPublicData;

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
const NO_INDEX = -1;

export class SignerSignFlowHelper {

    private _wizardRef: any;
    private _props?: SignerSignFlowProps;


    constructor(wizardRef: any, SignerSignFlowProps?: SignerSignFlowProps) {
        this._wizardRef = wizardRef;
        this._props = SignerSignFlowProps;
    }


    public static create(wizardRef: any, SignerSignFlowProps?: SignerSignFlowProps) {
        return new SignerSignFlowHelper(wizardRef, SignerSignFlowProps);
    }


    public static createNullObject() {
        return new SignerSignFlowHelper(null);
    }


    public signProcessRecoverConfirmation(lastVisitedStep: SignProcessSteps, clientId?: string, requestTutorialInfo?: (clientGuid: string) => any) {
        const _self = this;

        if (lastVisitedStep) {
            dialogBox.confirmCustom(
                SignProcessConstants.ProcessRecoveryConfirmation,
                SignProcessConstants.ProcessRecoveryMessage,
                SignProcessConstants.ProcessRecoveryConfirmationDialogLeftButton,
                SignProcessConstants.ProcessRecoveryConfirmationDialogRightButton, function (result: boolean) {

                    if (result) {

                        /* 10 - retry count until the step is visible based on the server call */
                        _self._wizardRef.goToStepById(lastVisitedStep, 10).then(function (success: boolean) {

                            if (success == false) {
                                //dialogBox.alert(SignProcessConstants.ProcessRecoveryFailedMessage);
                                _self._wizardRef.goToStep(0);
                            }
                        });
                    }
                    else {
                        _self._wizardRef.goToStep(0);
                    }
                    if (requestTutorialInfo && clientId) {
                        requestTutorialInfo(clientId);
                    }
                });
        }
        else {
            if (requestTutorialInfo && clientId) {
                requestTutorialInfo(clientId);
            }
        }
    }

    isLinkVisited(step: SignProcessSteps): boolean | undefined {
        let index = NO_INDEX;
        //index = this._props && this._props.signProcessInfo.visitedSteps ? this._props.signProcessInfo.visitedSteps.indexOf(step) : NO_INDEX;
        return (index !== null && index !== NO_INDEX)
    }
}
