import { Guid } from "../../../../../../core/utilities/Guid";
import { Document, IDocument, IPage, Page } from "../../../../../../model/Esign/Document"; 
import {
    CompanyControl, CustomTextControl, DateControl, IControl, ICustomData, InitialControl, ISignatureData, ITextData, SignatureControl,
    NameAndTitleControl, TextData, NameControl, TitleControl, CheckBoxControl, ICheckBoxData, ITextBoxControl, PrintNameControl, RadioControl, RadioGroupData
} from "../../../../../../model/Esign/IControls";

import { DocumentViewModel, ISignatureControlModel, ISignaturePageData, SignatureControlModel, SignatureControlRole, SignaturePageData }
    from '../../../../../../model/ViewModel/DocumentViewModel'

import { ISignerControlDataModal, SignerControlDataModal } from "../../../../../../model/Esign/ISignerControlDataModal";
import { ISignerInfo, IControlRole, SignerInfo, ControlRole } from "../../../../../../model/Esign/CustomData";

export interface IDocumentAdapter {
    convertToClientModel(documentModel: DocumentViewModel[]): IDocument[];
    convertToServerModel(document: IDocument[]): ISignerControlDataModal;
    convertToClientModelWithDisable(documentModel: DocumentViewModel[]): IDocument[];
}

const signatureTopAdjustment: number = 5;

export class DocumentAdapter implements IDocumentAdapter {

    public static create(): DocumentAdapter {
        return new DocumentAdapter();
    }

    public convertToClientModel(documentModel: DocumentViewModel[]): IDocument[] {

        let _self = this;
        let documents: IDocument[] = [];


        documentModel.forEach(function (document: DocumentViewModel) {

            let tmpDocument: IDocument = Document.create(document.id,
                document.name,
                document.fileLink,
                document.disabled,
                document.documentType, [],document.conditionalControlRules ? document.conditionalControlRules : []);

            document.documentControls.forEach(function (pageItem: ISignaturePageData) {

                let tmpControls: IControl[] = [];

                pageItem.signatureControls.forEach(function (controlItem: ISignatureControlModel) {

                    tmpControls.push(_self.convertToClientControlModel(controlItem));

                });

                tmpDocument.pages.push(Page.create(pageItem.pageNo, tmpControls));

            });

            documents.push(tmpDocument);

        });

        return documents;

    }

    public convertToServerModel(documents: IDocument[]): ISignerControlDataModal {

        let _self = this;
        let signerControlDataModal: ISignerControlDataModal = SignerControlDataModal.create("", "", [], "");

        let documentsModel: DocumentViewModel[] = [];

        documents.forEach(function (document: IDocument) {

            let tmpDocument: DocumentViewModel = DocumentViewModel.create(document.id, document.name, document.url, document.disabled, document.documentType, [], document.conditionalControlsRules);

            document.pages.forEach(function (page: IPage) {

                let tmpControls: ISignatureControlModel[] = [];

                page.controls.forEach(function (control: IControl) {
                    tmpControls.push(_self.convertToServerControlModel(control, signerControlDataModal));

                });

                tmpDocument.documentControls.push(SignaturePageData.create(page.page, tmpControls));

            });

            documentsModel.push(tmpDocument);

        });

        signerControlDataModal.documentData = documentsModel;

        return signerControlDataModal;
    }

    public convertToClientModelWithDisable(documentModel: DocumentViewModel[]): IDocument[] {

        let _self = this;
        let documents: IDocument[] = [];


        documentModel.forEach(function (document: DocumentViewModel) {

            let tmpDocument: IDocument = Document.create(document.id,
                document.name,
                document.fileLink,
                document.disabled,
                document.documentType, [], document.conditionalControlRules ? document.conditionalControlRules : []);

            document.documentControls.forEach(function (pageItem: ISignaturePageData) {

                let tmpControls: IControl[] = [];

                pageItem.signatureControls.forEach(function (controlItem: ISignatureControlModel) {

                    tmpControls.push(_self.convertToClientControlModelWithDisable(controlItem));

                });

                tmpDocument.pages.push(Page.create(pageItem.pageNo, tmpControls));

            });

            documents.push(tmpDocument);

        });

        return documents;

    }


    private convertToServerControlModel(control: IControl, signerControlDataModal: ISignerControlDataModal): ISignatureControlModel {

        let signatureControlModel: ISignatureControlModel;

        const signerInfo: ICustomData | undefined = control.customData.find(x => (x instanceof SignerInfo) == true);
        const controlRole: ICustomData | undefined = control.customData.find(x => (x instanceof ControlRole) == true);

        const signer: string = (signerInfo as ISignerInfo).email;
        const role: SignatureControlRole = (controlRole as IControlRole).role;

        if (control instanceof SignatureControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                1,
                control.top + signatureTopAdjustment, // to fix small change in signature position bug - #41668
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { hasValueIfOptional: (control.data && (control.data as ISignatureData).signature.trim().length > 0) ? true : false }, // Will be used to check if the person signed when the control is optional
                [],
                control.id
            );

            if (signerControlDataModal.signature == null ||
                signerControlDataModal.signature == undefined ||
                (signerControlDataModal.signature != null && signerControlDataModal.signature != undefined && signerControlDataModal.signature.trim() == "")) {
                if (control.data != undefined && control.data != null) {
                    signerControlDataModal.signature = (control.data as ISignatureData).signature;
                }
            }
        }
        else if (control instanceof InitialControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                2,
                control.top + signatureTopAdjustment,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { hasValueIfOptional: (control.data && (control.data as ISignatureData).signature.trim().length > 0) ? true : false },
                [],control.id);

            if (signerControlDataModal.signatureInitial == null ||
                signerControlDataModal.signatureInitial == undefined ||
                (signerControlDataModal.signatureInitial != null && signerControlDataModal.signatureInitial != undefined && signerControlDataModal.signatureInitial.trim() == "")) {
                if (control.data != undefined && control.data != null) {
                    signerControlDataModal.signatureInitial = (control.data as ISignatureData).signature;
                }
            }
        }
        else if (control instanceof DateControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                3,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text },
                [],control.id);

        }
        else if (control instanceof NameAndTitleControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                4,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text },
                [],control.id);

        }
        else if (control instanceof CompanyControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                5,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text },
                [],control.id);

        }
        else if (control instanceof CustomTextControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                6,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text },
                [],control.id);
        }
        else if (control instanceof NameControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                7,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text },
                [],control.id);

        }
        else if (control instanceof TitleControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                8,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text },
                [],control.id);

        }
        else if (control instanceof CheckBoxControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                9,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { checked: control.data != undefined && control.data != null ? (control.data as ICheckBoxData).checked : false },
                [],control.id);

        }
        else if (control instanceof RadioControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                11,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                control.data != undefined && control.data != null ? control.data : "",
                control.items,
                control.id
            );

        }
        else if (control instanceof PrintNameControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                10,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text },
                [],control.id);

        }
        else {
            throw new Error("Method not implemented.");
        }

        return signatureControlModel;

    }


    private convertToClientControlModel(control: ISignatureControlModel): IControl {

        let model: IControl;
        let customData: ICustomData[] = [];

        customData.push(SignerInfo.create(control.signer));
        customData.push(ControlRole.create(control.role));

        control.controlGuid = Guid.isNullOrEmpty(control.controlGuid) 
            ? Guid.newGuid().toString()
            : control.controlGuid;

        switch (control.type) {

            case 1: // signature

                model = SignatureControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, undefined);

                break;

            case 2: // Initial                

                model = InitialControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, undefined);

                break;

            case 3: // Date

                model = DateControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, TextData.create(this.getCurrentDate()));

                break

            case 4: // Name and title

                model = NameAndTitleControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined);

                break;


            case 5: // company

                model = CompanyControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined);

                break;

            case 6: // Text

                model = CustomTextControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined);

                break;

            case 7: // Name

                model = NameControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined);

                break;

            case 8: // Title

                model = TitleControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined);

                break;

            case 9: // CheckBox

                model = CheckBoxControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, undefined);

                break;

            case 10: //PrintName

                model = PrintNameControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined);
                break;
            case 11: //Radio button

                const newGuid = Guid.newGuid().toString();
                const newItems = control.items.map((item) => ({...item, name: newGuid}));

                model = RadioControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, newItems, RadioGroupData.create(newItems), undefined);
                break;

            default:

                throw new Error("Method not implemented.");

        }

        return model;

    }


    private getCurrentDate = (): string => {

        var today = new Date();
        var dd = today.getDate().toString();

        var mm = (today.getMonth() + 1).toString();
        var yyyy = today.getFullYear();
        if (Number(dd) < 10) {
            dd = '0' + dd;
        }

        if (Number(mm) < 10) {
            mm = '0' + mm;
        }

        return mm + '/' + dd + '/' + yyyy;
    }

    private convertToClientControlModelWithDisable(control: ISignatureControlModel): IControl {

        let model: IControl;
        let customData: ICustomData[] = [];

        customData.push(SignerInfo.create(control.signer));
        customData.push(ControlRole.create(control.role));

        if (control.required === undefined || control.required === null) {
            control.required = true;
        }

        control.controlGuid = Guid.isNullOrEmpty(control.controlGuid) 
            ? Guid.newGuid().toString()
            : control.controlGuid;

        switch (control.type) {

            case 1: // signature

                model = SignatureControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, undefined, true);

                break;

            case 2: // Initial                

                model = InitialControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, undefined, true);

                break;

            case 3: // Date

                model = DateControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, TextData.create(this.getCurrentDate()), true);

                break

            case 4: // Name and title

                model = NameAndTitleControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined, true);

                break;


            case 5: // company

                model = CompanyControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined, true);

                break;

            case 6: // Text

                model = CustomTextControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined, true);

                break;

            case 7: // Name

                model = NameControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined, true);

                break;

            case 8: // Title

                model = TitleControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined, true);

                break;

            case 9: // CheckBox

                model = CheckBoxControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, undefined, true);

                break;

            case 10: //PrintName

                model = PrintNameControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined, true);
                break;
            case 11: // Radio button

                const newGuid = Guid.newGuid().toString();
                const newItems = control.items.map((item) => ({...item, name: newGuid}));

                model = RadioControl.create(control.controlGuid, control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, newItems, RadioGroupData.create(newItems), true);
                break;
            default:
                throw new Error("Method not implemented.");

        }

        return model;

    }
}